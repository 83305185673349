import React from "react";
import { Divider } from "antd";
const Footer = () => {
  return (
    <footer className="container py-1" id="footercontainer">
      <Divider className="mt-1" />
      <div className="row">
        <div className="col-12 col-md">
          <small className="d-block mb-3 text-muted">
            <img
              alt="图标"
              src="./images/icon.svg"
              width="20"
              height="20"
              id="iconfooter"
            />
            Copyright © 2024 保留所有权利
          </small>
        </div>
        <div className="col-6 col-md">
          <h5>专利</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="link-secondary" href="https://www.cnipa.gov.cn/">
                国知局
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="http://ycjy.acpaa.cn/main.htm"
              >
                代理师远程教育培训
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://tysf.cponline.cnipa.gov.cn/am/#/user/login"
              >
                中国及多国
              </a>
            </li>
            <li>
              <a className="link-secondary" href="http://dlgl.cnipa.gov.cn/">
                专利代理系统
              </a>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>论文</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a
                className="link-secondary"
                href="https://www.wforce.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WoS
              </a>
            </li>
            <li>
              <a className="link-secondary" href="https://www.cnki.net/">
                知网
              </a>
            </li>
            <li>
              <a className="link-secondary" href="https://scholar.google.hu/">
                Google 学术
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://pubmed.ncbi.nlm.nih.gov/"
              >
                PubMed
              </a>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>关于</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="link-secondary" href="#">
                团队
              </a>
            </li>
            <li>
              <a className="link-secondary" href="#">
                邮箱：1139100257@qq.com
              </a>
            </li>
            <li>
              <a className="link-secondary" href="#">
                隐私和法律
              </a>
            </li>
            <li>
              <a className="link-secondary" href="#">
                最新消息
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-icp">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">
            京ICP备2024077165号
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <img
            src="/images/beianicon.png"
            width="15"
            height="15"
            alt="公安备案图标"
          ></img>
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=11011202100742"
            rel="noreferrer"
            target="_blank"
          >
            京公网安备11011202100742
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
