import React, { useState } from "react";
import AiWriteTypeWriter from "./units/typewriter/aiwrite/AiWriteTypeWriter";
import AiWriteService from "../services/aiwrite_service";
const Aiwrite_component = () => {
  // 设置按钮禁用状态变量
  const [aiZhiNengWenDaState, setAiZhiNengWenDaState] = useState(false);
  const [aiJianChaZhiLiangState, setAiJianChaZhiLiangState] = useState(false);
  const [aiZhuanliFanYi, setAiZhuanliFanYi] = useState(false);
  const [aiJiShuXiaoGuo, setAiJiShuXiaoGuo] = useState(false);
  const [aiBeiJingJiShu, setAiBeiJingJiShu] = useState(false);
  const [aiJiShuJiaoDi, setAiJiShuJiaoDi] = useState(false);
  const [aiZhuanLiWaJue, setAiZhuanLiWaJue] = useState(false);
  const [aiQuBieTeZheng, setAiQuBieTeZheng] = useState(false);
  const [aiLiJieWenJian, setAiLiJieWenJian] = useState(false);
  // 获取输入框中的数据
  const [questionData, setQuestionData] = useState();
  // 获取输出框中的数据
  const [answerData, setAnswerData] = useState();
  const handleQuestion = (e) => {
    setQuestionData(e.target.innerText);
  };
  // 将数据发送到后端，得到结果
  // AI智能回答
  const handleQuestionService = async () => {
    setAiZhiNengWenDaState(true);
    try {
      let response = await AiWriteService.AiChatService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI智能回答出错", err);
    } finally {
      setAiZhiNengWenDaState(false);
    }
  };
  // AI检查质量
  const handleAiCheckService = async () => {
    setAiJianChaZhiLiangState(true);
    try {
      let response = await AiWriteService.AiCheckService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI检查出错", err);
    } finally {
      setAiJianChaZhiLiangState(false);
    }
  };

  // AI专利翻译
  const handleTranslateService = async () => {
    setAiZhuanliFanYi(true);
    try {
      let response = await AiWriteService.AiTranslateService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI专利翻译出错", err);
    } finally {
      setAiZhuanliFanYi(false);
    }
  };
  // AI技术效果
  const handleAiTechEffectService = async () => {
    setAiJiShuXiaoGuo(true);
    try {
      let response = await AiWriteService.AiTechEffectService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI技术效果出错", err);
    } finally {
      setAiJiShuXiaoGuo(false);
    }
  };
  // AI背景技术
  const handleAiBackGroundService = async () => {
    setAiBeiJingJiShu(true);
    try {
      let response = await AiWriteService.AiBackGroundService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI背景技术出错", err);
    } finally {
      setAiBeiJingJiShu(false);
    }
  };
  // AI技术交底
  const handleAiTechBottomService = async () => {
    setAiJiShuJiaoDi(true);
    try {
      let response = await AiWriteService.AiTechBottomService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI技术交底出错", err);
    } finally {
      setAiJiShuJiaoDi(false);
    }
  };
  // AI专利挖掘
  const handleAipatentMiningService = async () => {
    setAiZhuanLiWaJue(true);
    try {
      let response = await AiWriteService.AipatentMiningService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI专利挖掘出错", err);
    } finally {
      setAiZhuanLiWaJue(false);
    }
  };
  // AI区别特征
  const handleAifindDifferenceService = async () => {
    setAiQuBieTeZheng(true);
    try {
      let response = await AiWriteService.findDifferenceService(questionData);
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI区别特征出错", err);
    } finally {
      setAiQuBieTeZheng(false);
    }
  };
  // AI理解文件
  const handleAiquickUnderstandingService = async () => {
    setAiLiJieWenJian(true);
    try {
      let response = await AiWriteService.quickUnderstandingService(
        questionData
      );
      setAnswerData(response);
      // 将结果赋值给输出框
    } catch (err) {
      console.error("AI理解文件出错", err);
    } finally {
      setAiLiJieWenJian(false);
    }
  };

  return (
    <>
      <main className="aiwriteBody">
        {/* 主体容器：包含输入框、按钮组、输出框 */}
        <section className="container text-center aiwriteUi">
          <h2 className="text-center aiwriteHeader">
            <AiWriteTypeWriter />
          </h2>
          <div className="row firstLine">
            {/* 输入框：用户输入内容 */}
            <div
              className="col-lg-5 col-sm-12 aiwriteInput"
              contentEditable="true"
              onInput={handleQuestion}
            />
            {/* 按钮组：包含多个操作按钮 */}
            <div className="col-lg-2 col-sm-12 aiwriteBtnDiv">
              {/* AI聊天对话按钮 */}
              <button
                onClick={handleQuestionService}
                disabled={aiZhiNengWenDaState}
                type="button"
                className="btn btn-primary aiwriteSonBtn aiChatBtn"
              >
                AI智能回答
              </button>
              {/* AI检查按钮 */}
              <button
                onClick={handleAiCheckService}
                disabled={aiJianChaZhiLiangState}
                type="button"
                className="btn btn-primary aiwriteSonBtn aiChatBtn"
              >
                AI检查质量
              </button>
              {/* AI专利翻译按钮 */}
              <button
                onClick={handleTranslateService}
                disabled={aiZhuanliFanYi}
                type="button"
                className="aiTranslateBtn btn btn-primary aiwriteSonBtn"
              >
                Ai专利翻译
              </button>
              {/* AI技术效果按钮 */}
              <button
                onClick={handleAiTechEffectService}
                disabled={aiJiShuXiaoGuo}
                type="button"
                className="aiwriteEffBtn btn btn-primary aiwriteSonBtn"
              >
                Ai技术效果
              </button>
              {/* AI背景技术按钮 */}
              <button
                onClick={handleAiBackGroundService}
                disabled={aiBeiJingJiShu}
                type="button"
                className="aiwriteBackgBtn btn btn-primary aiwriteSonBtn"
              >
                Ai背景技术
              </button>
              {/* AI技术交底按钮 */}
              <button
                onClick={handleAiTechBottomService}
                disabled={aiJiShuJiaoDi}
                type="button"
                className="aiwriteTechBottom Btn btn btn-primary aiwriteSonBtn"
              >
                Ai技术交底
              </button>
              {/* AI专利挖掘按钮 */}
              <button
                onClick={handleAipatentMiningService}
                disabled={aiZhuanLiWaJue}
                type="button"
                className="aiwriteWajueBtn btn btn-primary aiwriteSonBtn"
              >
                Ai专利挖掘
              </button>
              {/* AI区别特征按钮 */}
              <button
                onClick={handleAifindDifferenceService}
                disabled={aiQuBieTeZheng}
                type="button"
                className="btn btn-primary aiwriteSonBtn aiwriteQubieBtn"
              >
                Ai区别特征
              </button>
              {/* AI理解文件按钮 */}
              <button
                onClick={handleAiquickUnderstandingService}
                disabled={aiLiJieWenJian}
                type="button"
                className="aiwriteUnderStandBtn btn btn-primary aiwriteSonBtn"
              >
                Ai理解文件
              </button>
              {/* 使用方法按钮：提示用户如何使用该功能 */}
              <button
                onClick={() =>
                  alert(
                    "以ai技术效果这个功能为例，假如您想让ai来写说明书技术效果，可以先将背景技术、技术问题、以及某项权利要求书，粘贴进左边输入框，然后点击ai技术效果这个按钮即可在右侧输出框中输出结果"
                  )
                }
                type="button"
                className="btn btn-secondary aiwriteSonBtn"
              >
                使用方法
              </button>
            </div>
            {/* 输出框：显示AI生成的结果 */}
            <div
              dangerouslySetInnerHTML={{ __html: answerData }}
              className="col-lg-5 col-sm-12 aiwriteOutput"
              contentEditable="true"
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default Aiwrite_component;
