import Layout from "./components/Layout_component";
import Home from "./components/Home_component";
import Write from "./components/Write_component";
import Check from "./components/Check_component";
import Aiwrite from "./components/Aiwrite_component";
import Ainav from "./components/Ainav_component";
import Searchnav from "./components/Searchnav_component";
import Todoist from "./components/Todoist_component";
import Figures from "./components/Figures_component";
import React from "react";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// 懒加载 Book、Draw、Xmind 组件（删除了重复的普通导入）
const Book = lazy(() => import("./components/Book_component"));
const Draw = lazy(() => import("./components/Draw_component"));
const Xmind = lazy(() => import("./components/Xmind_component"));

function App() {
  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Suspense fallback={<div>加载中...</div>}>
        <Routes>
          {/* 根路由，渲染 Layout */}
          <Route path="/" element={<Layout />}>
            {/* 默认首页 */}
            <Route index element={<Home />} />
            {/* 子路由：/todolist */}
            <Route path="todolist" element={<Todoist />} />
            {/* 子路由：/write */}
            <Route path="write" element={<Write />} />
            {/* 子路由：/aiwrite */}
            <Route path="aiwrite" element={<Aiwrite />} />
            {/* 子路由：/figures */}
            <Route path="figures" element={<Figures />} />
            {/* 子路由：/draw */}
            <Route path="draw" element={<Draw />} />
            {/* 子路由：/mind */}
            <Route path="xmind" element={<Xmind />} />
            {/* 子路由：/check */}
            <Route path="check" element={<Check />} />
            {/* 子路由：/审查指南 */}
            <Route path="book" element={<Book />} />
            {/* 子路由：/searchnav */}
            <Route path="searchnav" element={<Searchnav />} />
            {/* 子路由：/ainav */}
            <Route path="ainav" element={<Ainav />} />
            {/* 404 =>首页页面 */}
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
