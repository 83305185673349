import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer_component";
import { Dropdown } from "antd";

// 菜单项
// 网址导航下拉菜单
const items_net_nav = [
  {
    key: "1",
    label: (
      <a
        style={{ color: "black" }}
        className="atagnav"
        rel="noopener noreferrer"
        href="/searchnav"
      >
        专利检索
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        style={{ color: "black" }}
        className="atagnav"
        rel="noopener noreferrer"
        href="/ainav"
      >
        人工智能
      </a>
    ),
  },
];
// 图片下拉菜单
const items_photo = [
  {
    key: "1",
    label: (
      <a
        style={{ color: "black" }}
        className="atagnav"
        rel="noopener noreferrer"
        href="/figures"
      >
        转CAD线稿
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        style={{ color: "black" }}
        className="atagnav"
        rel="noopener noreferrer"
        href="/xmind"
      >
        绘制Xmind图
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        style={{ color: "black" }}
        className="atagnav"
        rel="noopener noreferrer"
        href="/draw"
      >
        绘制草稿图
      </a>
    ),
  },
];
// Layout 组件
const Layout_component = () => {
  return (
    <>
      <div className="container">
        <header className="d-flex flex-wrap justify-content-center py-3  border-bottom">
          <a
            href="/"
            className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
          >
            <img
              src="/images/icon.svg"
              width="30"
              height="30"
              alt="Product Logo"
            />
          </a>

          <ul className="nav nav-pills">
            <li className="nav-item">
              <a href="/" className="nav-link" aria-current="page">
                首页
              </a>
            </li>
            <li className="nav-item">
              <a href="/todolist" className="nav-link">
                提醒事项
              </a>
            </li>
            <li className="nav-item">
              <a href="/write" className="nav-link">
                撰写
              </a>
            </li>
            <li className="nav-item">
              <a href="/aiwrite" className="nav-link">
                AI辅助撰写
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <Dropdown
                  placement="bottom"
                  overlayStyle={{
                    color: "purple",
                  }}
                  menu={{
                    items: items_photo,
                  }}
                >
                  <a
                    style={{ textDecoration: "none" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    图片
                  </a>
                </Dropdown>
              </a>
            </li>
            <li className="nav-item">
              <a href="/check" className="nav-link">
                自检
              </a>
            </li>
            <li className="nav-item">
              <a href="/book" className="nav-link">
                审查指南
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <Dropdown
                  placement="bottom"
                  overlayStyle={{
                    color: "purple",
                  }}
                  menu={{
                    items: items_net_nav,
                  }}
                >
                  <a
                    style={{ textDecoration: "none" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    网址导航
                  </a>
                </Dropdown>
              </a>
            </li>
          </ul>
        </header>
        <div  className="z-3 position-fixed "> <img className=" shadow-sm p-0 mb-5 bg-body-tertiary rounded" src="/images/donate.jpg" width={75} ></img></div>
       
      </div>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout_component;
